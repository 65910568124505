import React from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

// icons
import success from './assets/success.svg';

// components
import Modal from '../../../../../../components/Modal';
import Heading from '../../../../../../components/Heading/MobileView';
import Button from '../../../../../../components/Button';

// styles
import styles from './SuccessMessage.styl';

const cx = classNames.bind(styles);

const SuccessMessage = ({ isOpen, onClose }) => {
  const intl = useIntl();

  return (
    <Modal className={cx('SuccessMessageModal')} isOpen={isOpen} onClose={onClose}>
      <img className={cx('SuccessMessageModal__img')} src={success} alt="success" />
      <Heading className={cx('SuccessMessageModal__subtitle')} level={2}>
        {intl.formatMessage({ id: 'page.landing.signup.success' })}
      </Heading>
      <Button className={cx('SuccessMessageModal__button')} onClick={onClose}>
        {intl.formatMessage({ id: 'button.greeting.acception' })}
      </Button>
    </Modal>
  );
};

SuccessMessage.propTypes = {
  isOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessMessage;
