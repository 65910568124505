import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';

// Components
import Container from '../../../components/Container';
import Button from '../../../components/Button';

// Partials
import useStock from '../useStock';

// Icons
import BackIcon from '../../../icons/back2.svg';

// State
import { applyStock } from '../../../state/modules/stock/actions';
import { stockSelector } from '../../../state/modules/stock/selectors';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Styles
import styles from './Stock.styl';

const cx = classNames.bind(styles);

export default function StockPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { alias } = useParams();
  const { title, image, full_text, stock_code } = useSelector(state => stockSelector(state, alias));
  const isLoaded = useSelector(state => state.stock.list.isLoaded);
  const activeStockCode = useSelector(state => state.stock.data.code);
  const isApplying = useSelector(state => state.stock.isApplying);

  const { helmet } = useStock();

  const onApply = code => {
    dispatch(applyStock({ stock_code: code }));
  };

  return (
    <Container className={cx('Stock')}>
      {helmet}
      <Link className={cx('Stock__back')} to={getRoute(paths.stock)}>
        <BackIcon />
        {intl.formatMessage({ id: 'stock.all.title' })}
      </Link>
      {isLoaded ? (
        <>
          <figure className={cx('Stock__image')}>
            <img src={image} alt={title} />
          </figure>
          <div className={cx('Stock__title')}>{title}</div>
          <div
            className={cx('Stock__description')}
            dangerouslySetInnerHTML={{
              __html: full_text,
            }}
          />
          {stock_code && (
            <Button
              className={cx('Stock__button')}
              secondary
              onClick={() => onApply(stock_code)}
              isLoading={isApplying}
              disabled={stock_code === activeStockCode}
            >
              {intl.formatMessage({
                id: stock_code === activeStockCode ? 'stock.button.applied' : 'stock.button.apply',
              })}
            </Button>
          )}
        </>
      ) : (
        <div className={cx('Stock__spinner')} />
      )}
    </Container>
  );
}
