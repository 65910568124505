import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import { Transition } from 'react-transition-group';

// Components
import { Link, useLocation } from 'react-router-dom';
import removeCookie from '../removeCookie';
import Container from '../../../Container';
import Button from '../../../Button';
import CustomCheckbox from '../CustomCheckbox';
import logoEn from '../../../Logo/assets/logo_en_new.svg';

// Utils
import isomorphicCookies, { IsomorphicCookies } from '../../../../utils/cookie/isomorphicCookies';

// Styles
import styles from './CookiePolicyPopup.styl';

import analyticalCookiesKeysList from '../../../../utils/cookie/analyticalCookiesKeysList';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCookiePopup } from '../../../../state/modules/ui/actions';

const cx = classNames.bind(styles);

export default function CookiePolicyPopup() {
  const intl = useIntl();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isAnalyticalCheckboxValue, setIsAnalyticalCheckboxValue] = useState(true);
  const { _supertag } = useSelector(state => state.city.userCity);
  const handleAnalyticalCheckbox = useCallback(() => {
    setIsAnalyticalCheckboxValue(!isAnalyticalCheckboxValue);
  }, [isAnalyticalCheckboxValue]);
  const dispatch = useDispatch();

  const pathName = useLocation();

  const isCookiePopupOpen = useSelector(state => state.ui.isCookiePopupOpen);

  useEffect(() => {
    isCookiePopupOpen && setIsPopupVisible(true);
  }, [isCookiePopupOpen]);

  useEffect(() => {
    if (isomorphicCookies().get('cookies_policy_accept')) {
      const cookiesPolicyAccept = isomorphicCookies().get('cookies_policy_accept').split(',');
      IsomorphicCookies.analyticalCookies = cookiesPolicyAccept[0];
      if (!cookiesPolicyAccept[0]) {
        removeCookie(analyticalCookiesKeysList);
      }
    } else {
      setIsPopupVisible(true);
    }

    if (
      pathName.pathname.includes('user-agreement') ||
      pathName.pathname.includes('personal-data-processing-policy')
    ) {
      setIsPopupVisible(false);
    }
  }, []);

  const onAllAccept = useCallback(() => {
    setIsAnalyticalCheckboxValue(true);
    isomorphicCookies().set('cookies_policy_accept', true, { expires: 7 });

    setIsPopupVisible(false);
    dispatch(toggleCookiePopup(false));
  }, [setIsAnalyticalCheckboxValue, setIsPopupVisible]);

  const onRefused = useCallback(() => {
    isomorphicCookies().set('cookies_policy_accept', false, { expires: 7 });
    removeCookie(analyticalCookiesKeysList);
    setIsPopupVisible(false);
    dispatch(toggleCookiePopup(false));
  }, [setIsPopupVisible, analyticalCookiesKeysList]);

  const onAccept = useCallback(() => {
    const value = `${isAnalyticalCheckboxValue}`;
    isomorphicCookies().set('cookies_policy_accept', value, { expires: 7 });

    if (!isAnalyticalCheckboxValue) {
      removeCookie(analyticalCookiesKeysList);
    }

    setIsPopupVisible(false);
    dispatch(toggleCookiePopup(false));
  }, [
    isAnalyticalCheckboxValue,
    analyticalCookiesKeysList,
  ]);
  const policyLink = `/${_supertag}/personal-data-processing-policy`;

  return (
    <Transition in={isPopupVisible} timeout={{ enter: 0, exit: 500 }} mountOnEnter unmountOnExit>
      {state => (
        <div className={cx('CookiePolicyPopup-background')}>
          <div className={cx('CookiePolicyPopup', `CookiePolicyPopup_${state}`)}>
            <Container className={cx('CookiePolicyPopup__content')}>
              <img style={{ width: '20%' }} src={logoEn} alt="Papa John's" />
              <div className={cx('CookiePolicyPopup__content-title')}>
                {intl.formatMessage({ id: 'cookie.header' })}
              </div>
              <div className={cx('CookiePolicyPopup__content-text')}>
                {intl.formatMessage({ id: 'cookie.subheader' })}
              </div>
              <div className={cx('CookiePolicyPopup__content-checkboxs-group')}>
                <CustomCheckbox
                  label={intl.formatMessage({ id: 'cookie.strictly.checkbox' })}
                  className={cx('CookiePolicyPopup__content-checkbox')}
                  value
                />
                <div className={cx('CookiePolicyPopup__content-checkbox-description')}>
                  {intl.formatMessage({ id: 'cookie.strictly.checkbox.text' })}
                </div>
                <CustomCheckbox
                  label={intl.formatMessage({ id: 'cookie.analytical.checkbox' })}
                  className={cx('CookiePolicyPopup__content-checkbox')}
                  onChange={handleAnalyticalCheckbox}
                  value={isAnalyticalCheckboxValue}
                />
                <div className={cx('CookiePolicyPopup__content-checkbox-description')}>
                  {intl.formatMessage({ id: 'cookie.analytical.checkbox.text' })}
                </div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                gap: '24px',
              }}>
                <Button className={cx('CookiePolicyPopup__content-refused')} onClick={onRefused}>
                  {intl.formatMessage({ id: 'cookie.refused.button' })}
                </Button>
                <Button className={cx('CookiePolicyPopup__content-button')} onClick={onAccept}>
                  {intl.formatMessage({ id: 'cookie.submit2.button' })}
                </Button>
                <Button className={cx('CookiePolicyPopup__content-button')} onClick={onAllAccept}>
                  {intl.formatMessage({ id: 'cookie.submit.button' })}
                </Button>
              </div>

              <div className={cx('CookiePolicyPopup__content-footer')}>
                <Link to={policyLink} target="_blank">
                  {`${intl.formatMessage({
                    id: 'cookie.privacy.policy.and.cookie.details',
                  })}`}
                </Link>
                <Link to="/user-agreement" target="_blank">
                  {` ${intl.formatMessage({
                    id: 'cookie.user.agreement',
                  })}`}
                </Link>
              </div>
            </Container>
          </div>
        </div>
      )}
    </Transition>
  );
}
