import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import CheckIcon from '../../../icons/check2.svg';

// Styles
import styles from './CustomCheckbox.styl';

const cx = classNames.bind(styles);

export default function CustomCheckbox(props) {
  const { className, label, name, ...otherProps } = props;

  const inputProps = {
    ...otherProps,
    type: 'checkbox',
  };

  return (
    <label
      className={cx('CheckboxField', className, {
        CheckboxField_checked: otherProps.value,
        CheckboxField_disabled: otherProps.disabled,
      })}
      htmlFor={name}
    >
      <input className={cx('CheckboxField__input')} {...inputProps} />
      <div className={cx('CheckboxField__check')}>
        {otherProps.value && <CheckIcon className={cx('CheckboxField__icon')} />}
      </div>
      <div className={cx('CheckboxField__label')}>{label}</div>
    </label>
  );
}

CustomCheckbox.defaultProps = {
  className: '',
  type: 'checkbox',
};

CustomCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  inputRef: PropTypes.string,
  big: PropTypes.bool,
  children: PropTypes.any,
};
