import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

import styles from './DesktopView.styl';

import landingPic from './assets/landing2.jpg';

// components
import Container from '../../../../components/Container';
import SignUpForm from './partials/SignUpForm/SignUpForm';
import SuccessMessage from './partials/SuccessMessage/SuccessMessage';

const cx = classNames.bind(styles);

export default function LandingPage() {
  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSuccessCallback = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <>
      <Container className={cx('Landing')}>
        <h1 className={cx('Landing__header')}>
          {intl.formatMessage({ id: 'page.landing.opening.soon' })}
        </h1>
        <h1 className={cx('Landing__header')}>
          {intl.formatMessage({ id: 'page.landing.header' })}
        </h1>
        <div className={cx('Landing__content')}>
          <div className={cx('Landing__content-left')}>
            <p className={cx('Landing__content-left_text')}>
              {intl.formatMessage({ id: 'page.landing.discount' })}
            </p>
            <p className={cx('Landing__content-left_text')}>
              {intl.formatMessage({ id: 'page.landing.signup.needed' })}
            </p>
            <SignUpForm onSuccessCallback={onSuccessCallback} />
          </div>
          <div className={cx('Landing__content-picture')}>
            <img src={landingPic} alt="landing" />
          </div>
        </div>
      </Container>
      <SuccessMessage isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}
