import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

// State
import { fetchStockListIfNeeded } from '../../state/modules/stock/actions';
import { stockSelector } from '../../state/modules/stock/selectors';

export default function StockPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { alias } = useParams();
  const { name, declension } = useSelector(state => state.city.userCity);
  const { title } = useSelector(state => stockSelector(state, alias));

  const helmet = (
    <Helmet>
      <title>{intl.formatMessage({ id: 'page.stock.helmet' }, { title, declension, name })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: 'page.stock.helmet.description' }, { declension, name })}
      />
    </Helmet>
  );

  useEffect(() => {
    dispatch(fetchStockListIfNeeded());
  }, []);

  return { helmet };
}
